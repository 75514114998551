#pres-prod {
    .row.details-cc{
        .col-md-8{
            .bouton {
                background-color: transparent;
                border: 2px solid #ad6d49;
                border-radius: 23px;
                padding: 10px 0;
                letter-spacing: 1.05px;
                font-size: 13px;
                color: #ad6d49 !important;
                display: block;
                width: 45%;
                text-align: center;
                margin-right: 0;
            }
            
            .bouton:hover {
                background-color: #ad6d49;
                color: white !important;
                cursor: url(/images/Cursor_1.png) 5 0, pointer;
            }
        }
        
    }
    
    article {
        display: flex;
        justify-content: center;
        font-family: "Poppins", sans-serif;
        margin-bottom: 90px;
        padding-top: 25px;
        flex-wrap: wrap;
        
        .col-md-4:first-child {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 50px 60px 105px 0;
            max-width: 360px;
            padding-right: 0;
            padding-left: 0;
            img { width: 350px; height: 350px }
        }
        .col-md-4.desc {
            margin-top: 45px;
            padding-left: 90px;
            
            h2 { font-family: "Poppins", sans-serif; font-size: 37px; font-weight: 600 }
            h3 { color: black !important; font-weight: 400; margin-top: 0; font-size: 22px }
            .prix {
                margin-bottom: 10px;
                font-size: 32px;
                font-weight: 600;
                color: #ad6d49;
            }
            label {
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: 0.45px;
                margin-right: 15px;
            }
            #quantite {  width: 80px; font-weight: 700; padding-left: 10px; margin-bottom: 25px }
            
            .bouton {
                background-color: transparent;
                border: 2px solid #ad6d49;
                border-radius: 23px;
                padding: 10px 0;
                letter-spacing: 1.05px;
                font-size: 13px;
                color: #ad6d49 !important;
                display: block;
                width: 75%;
                text-align: center;
            }
            
            .bouton:hover {
                background-color: #ad6d49;
                color: white !important;
                cursor: url(/images/Cursor_1.png) 5 0, pointer;
            }
            
            p:not(.prix) {
                margin-bottom: 50px;
                font-size: 14px;
                span { font-weight: 600 }
            }
            
            #popup-ajout {
                display: none;
                position: fixed;
                top: 0;
                left: 0;
                z-index: 100;
                background-color: rgba(0, 0, 0, 0.8);
                width: 100%;
                height: 100%;
                justify-content: center;
                align-items: center;
                
                .popup-body {
                    background-color: #f2eeec;
                    border-radius: 10px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    height: 300px;
                    width: 50%;

                    .check-basket-link {

                        i {
                            margin-right: 10px;
                        }

                        label {
                            cursor: pointer;
                            margin: 0
                        };
                    }
                    
                    p { font-size: 18px !important; margin-bottom: 55px !important; text-align: center }
                    
                    div {
                        width: 90%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-bottom: -30px;
                        
                        button.bouton { width: auto !important; padding-left: 15px; padding-right: 15px }
                        a.bouton { width: 160px; border: none; color: black !important; margin-top: 15px }
                        a.bouton:hover { background-color: transparent }
                        .fa { font-size: 20px }
                    }
                }
            }
        }
    }
    
    > div.row:not(.ariane) {
        display: flex;
        flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        justify-content: center;
        font-family: "Poppins", sans-serif;
        
        h3 { margin-bottom: 50px } 
        p { font-weight: 600; font-size: 15px; margin-top: 15px }
        .info { font-size: smaller; color: grey; padding-left: 3px }
        input, textarea { padding: 5px 0 5px 10px; margin-bottom: 10px; width: 100% }
        input[type=email] { margin-bottom: 5px }
        
        .bouton {
            background-color: transparent;
            border: 2px solid #ad6d49;
            border-radius: 23px;
            padding: 10px 0;
            letter-spacing: 1.05px;
            font-size: 13px;
            color: #ad6d49 !important;
            display: block;
            width: 45%;
            text-align: center;
            margin: 75px auto 0 auto;
        }
        
        .bouton:hover {
            background-color: #ad6d49;
            color: white !important;
            cursor: url(/images/Cursor_1.png) 5 0, pointer;
        }
        
        .col-md-8 div { padding: 0 }
        .col-md-8 .col-xs-7 { padding-left: 60px }
    }
    
    .ajout { margin-bottom: 150px;
        .popup-body p { font-weight: 400 !important; margin-top: 0 !important }
        .popup-body .bouton { margin: 0 !important }
        .popup-body a.bouton { margin-top: 15px !important }
    }
    
    .ariane {
        padding-top: 50px;
        margin-left: 150px;
        color: #777777;
        a { color: #777777; font-weight: 500 }
    }
}

#desc-prod {
    background-color: #f2eeec;
    flex-direction: column;
    display: flex;
    align-items: center;
    padding: 0 10px;
    
    .line-squares:first-child {
        margin: -7px 0 50px 0;
        .square {
            width: 15px;
            height: 15px;
            margin-right: 15px;
        }
    }
    
    .line-squares:last-child {
        margin: 50px 0 -7px 0;
        .square {
            width: 15px;
            height: 15px;
            margin-right: 15px;
        }
    }
    
    h3 { font-weight: 700; font-size: 19px; color: black !important }
    p { text-align: justify }
    
    ul {
        list-style: none;
        font-size: 16px;
        font-family: "Poppins", sans-serif;
        padding-left: 20px;
        
        li::before {
            content: "\2022";
            color: #ad6d49;
            display: inline-block;
            width: 1.2em;
            margin-left: -1em;
        }
    }
}

#produits {
    height: 954px;
    
    .row {
        display: flex;
        justify-content: center;
        margin-top: 90px;
        
        article, article > a {
            display: flex;
            flex-direction: column;
            align-items: center;
            
            p {
                text-align: center;
                line-height: 24px;
            }
        }
        
        .col-xs-2 {
            height: 400px;
            border: 1px solid #777777;
            background-color: #ffffff;
            margin-top: -15px;
            width: 15%;
            min-width: 250px;
            margin-right: 30px;
            position: relative;
            
            > a {
                width: 100%;
                height: 100%;
                
                p {
                    font-size: 20px;
                    line-height: 24px;
                    margin-top: 55px;
                    font-weight: bold;
                    color: black;
                    
                    span {
                        text-transform: uppercase;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 38px;
                    }
                }
                
                .price {
                    color: #ad6d49;
                    font-weight: 600;
                    font-size: 22px !important;
                    line-height: 23.96px;
                    margin-top: 25px !important;
                }
                
                img {
                    margin: 45px 0 0;
                    height: 135px;
                }
            }
            
            .voir-plus {
                opacity: 0;
                background-color: rgba(0, 0, 0, 0.8);
                width: 100%;
                height: 100%;
                position: absolute;
                transition: all .3s;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        
        .col-xs-2:hover .voir-plus { opacity: 1 }
        
        .bouton {
            background-color: transparent;
            border: 2px solid #ad6d49;
            border-radius: 23px;
            padding: 6px 20px;
            letter-spacing: 1.05px;
            font-size: 15px;
            color: #ad6d49 !important;
        }
        
        .bouton:hover {
            background-color: #ad6d49;
            color: white !important;
            cursor: url(/images/Cursor_1.png) 5 0, pointer;
        }
    }
    
    .filters {
        justify-content: start;
        margin: 0 0 -10px 0;
        position: relative;
        font-family: 'Poppins', sans-serif !important;
        
        select, input {
            width: 200px;
            padding: 10px;
            font-size: 15px;
            border: 1px solid #313131;
            background-color: white;
        }

        #categorie-select { margin-right: 15px; margin-left: -10px }
        #marque-select, #prix-select { margin-right: 15px }
        
        #shop-search {
            position: absolute;
            right: 20px;
            div { position: relative;
                input { width: 300px; padding-right: 45px }
                input::placeholder { opacity: 1 }
                #searchSubmit {
                    position: absolute;
                    right: 0;
                    cursor: url(/images/Cursor_1.png) 5 0, pointer;
                    width: 45px;
                    height: 100%;
                    background: none;
                    border: none;
                }
            }
        }
    }
    
    .num-page { width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border: 1px solid #cccccc;
        margin-right: 10px;
        font-weight: 600;
    }
    .num-page:hover { background-color: #ad6d49; cursor: url(/images/Cursor_1.png) 5 0, pointer; color: white }
    .num-page.active { background-color: #313131; color: white; border-color: #313131 }

    #liste-produits > div:last-child { margin-top: 20px; margin-bottom: 90px }
    #liste-produits .home-prod-wrap { flex-wrap: wrap }
}