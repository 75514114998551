#contact {
    min-height: 752px;
    background-image: url(/images/bg-contact.png);
    display: flex;
    justify-content: center;
    padding: 80px 0;
    width: 100%;
    margin: 0;
    flex-direction: column;
    align-items: center;
    
    .colonne {
        margin-bottom: 20px;

        .col {
            display: flex;
            flex-direction: column;
            padding-left: 0;
            
            .fa {
                color: #ad6d49;
                float: left;
                font-size: 28px;
            }
            
            p, a { font-size: 18px; margin-bottom: 0 }
            a {
                color: white;
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                line-height: 18px;
            }
    
            .info {
                display: flex;
                align-items: center;
                margin-bottom: 20px;
    
                .icon {
                    width: 33px;
                    display: flex;
                    justify-content: center;
                    margin-right: 15px;
                }

                .metro {
                    border-radius: 50%;
                    font-weight: bold;
                    color: #000;
                    text-align: center;
                    font-family: 'Poppins', sans-serif;display: inline-block;
                    width: 25px;
                    height: 25px;
                    margin-bottom: 5px;
                    margin-right: 4px;

                    &.m1 { background-color: #ffcd00; }
                    &.m9 { background-color: #ccc827; }
                }
            }
        }
    }

    #mapContact {
        border-radius: 10px;
        margin-top: -100px;
    }
    
    .row {
        width: 100%;
        margin-left: 0;
    }
    
    .line-squares { margin: 30px 0 40px 5px }
    
    h1 { font-size: 48px; text-align: left }
    
    .bouton {
        background-color: transparent;
        border: 2px solid #ad6d49;
        border-radius: 23px;
        letter-spacing: 1.05px;
        font-size: 15px;
        color: #ad6d49 !important;
        width: 100%;
        display: inline-block;
        text-align: center;
        margin-top: 30px;
        padding: 11px 0;
    }
    
    .bouton:hover {
        background-color: #ad6d49;
        color: #1b1716 !important;
        cursor: url(/images/Cursor_1.png) 5 0, pointer;
    }
}

footer {
    background-color: #1b1716;
    border-top: 2px solid #494645;
    
    .row {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        
        ul {
            border-right: 1px solid #4a4646;
            margin-top: 30px;
            list-style: none;
            padding-right: 15px;
            
            li {
                margin-top: 10px;
                text-transform: uppercase;
                font-family: "Poppins";
                
                a { color: white !important }
                a:hover { color: #ad6d49 !important; }
            }
        }
        .col-xs-2:last-child ul { border: none }
        .col-xs-2 { width: 19% }
        
        p {
            font-size: 12px !important;
            font-weight: 600 !important;
            padding: 10px 0px;
            margin-top: 15px;
        }
    }
}