.tarifs {
    background-color: #947462;
    
    .row { margin-left: 0; margin-right: 0 }
    
    > .row {
        display: flex;
        justify-content: center;
        margin-top: 70px;
    }
    
    > .row:first-child {
        .square { background-color: #1b1716 }
        
        article {
            display: flex;
            flex-direction: column;
            align-items: center;
            
            p {
                text-align: center;
                line-height: 24px;
            }
        }
    }
    
    > .row:last-child { margin-bottom: 50px }
    
    h2 { font-size: 30px; font-weight: 600 }
    
    .tarif-tab {
        .row {
            border-bottom: 1px solid #775b4c;
            padding: 15px 0;
            padding-right: 5px;
            font-size: 18px;
            span { padding: 0 15px 0 0 }
            span.black-text { font-weight: 600; letter-spacing: 0.5px; text-align: right }
        }
        .type-soin { padding-bottom: 25px }
    }
    
    .bouton {
        background-color: transparent;
        border: 2px solid white;
        border-radius: 18px;
        padding: 6px 30px;
        letter-spacing: 1.05px;
        font-size: 15px;
        color: white !important;
        margin-top: 75px;
    }
    
    .bouton:hover {
        background-color: white;
        color: #947462 !important;
        cursor: url(/images/Cursor_1.png) 5 0, pointer;
    }
}