#presentation {
    height: 515px;
    
    .pres-imgs {
        float: left;
        overflow: hidden;
        width: 50%;
        min-height: 515px;
        position: relative;
    }
    img { width: 1000px }
    
    article {
        margin: 50px 0 0 2.5%;
        display: block;
        width: 37%;
        float: left;
        
        h1 { text-align: left; font-size: 48px; }
        .line-squares { margin: 30px 0 30px 7px }
        
        p {
            line-height: 27.05px;
            letter-spacing: 1px;
            margin-bottom: 20px;
            font-size: 15px;

        }
        
        .bouton {
            background-color: transparent;
            border: 2px solid #ad6d49;
            border-radius: 18px;
            padding: 8px 30px;
            letter-spacing: 0.9px;
            font-size: 15px;
            color: #ad6d49 !important;
        }
        
        .bouton:hover {
            background-color: #ad6d49;
            color: white !important;
            cursor: url(/images/Cursor_1.png) 5 0, pointer;
        }
    }
}

#presentation.membre {
    height: 592px;
    background-color: #947462;
    display: flex;
    display: -webkit-flex;
    
    .square { background-color: #1b1716 }
    strong { color: black }
    #presentation .col-md-4 { width: 27% }

    .bouton {
        background-color: transparent;
        border: 2px solid white;
        border-radius: 18px;
        padding: 6px 30px;
        letter-spacing: 1.05px;
        font-size: 15px;
        color: white !important;
        margin-top: 75px;
    }
    .bouton:hover {
        background-color: white;
        color: #947462 !important;
        cursor: url(/images/Cursor_1.png) 5 0, pointer;
    }

    ul {
        font-family: "Poppins", sans-serif;
        padding-left: 20px;
        margin-bottom: 60px;
        font-size: 16px;
        line-height: 28.05px;
        font-weight: 500;
        letter-spacing: 0.5px;
    }

    article p { font-weight: 300 }
}

@media screen and (max-width: 1644px) {
    #presentation.membre { height: auto;
        article { width: auto !important }
    }
}

@media screen and (max-width: 985px) {
    #presentation img.pres { width: 1000px !important }
}