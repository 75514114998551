#gallery {
    background-color: #947462;
    
    .row {
        display: flex;
        justify-content: center;
        margin-top: 90px;
        
        article {
            display: flex;
            flex-direction: column;
            align-items: center;
            
            .square { background-color: #1b1716 }
        }
        
        .col-md-9 {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            
            div {
                width: 245px;
                height: 140px;
                margin: 0 5px 5px 0;
                overflow: hidden;
                
                img { transform: scale(1); transition: transform .3s; width: 100%; min-height: 100% }
                img:hover { transform: scale(1.1); cursor: url(/images/Cursor_1.png) 5 0, pointer }
            }
        }
    }
    
    .gal { margin-bottom: 120px; margin-top: 60px }
}

#gallery-open {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: none;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.8);
    justify-content: center;
    align-items: center;
    
    img {
        display: none;
        max-width: 90%;
        max-height: 90%;
        border: 2px solid #ad6d49;
    }
}