@import url('https://use.fontawesome.com/releases/v5.8.1/css/all.css');
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
@import url('https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css');
@import url('https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');
@import url('https://fonts.googleapis.com/css?family=EB+Garamond:400,400i,500,500i,600,600i,700,700i,800,800i');

@import "/layout/header.scss";

body, html { width: 100%; overflow-x: hidden; }
body { overflow: hidden }

a { transition: color .2s; }
a:hover {
    text-decoration: none !important;
    color: #ad6d49 !important;
    cursor: url("/images/Cursor_1.png") 5 0, pointer !important;
}

button:hover { cursor: url("/images/Cursor_1.png") 5 0, pointer !important; }

h1 {
    font-size: 50px;
    font-family: 'EB Garamond', serif;
    font-weight: 600;
    letter-spacing: 1.2px;
    text-align: center;
}

h2 {
    font-family: 'EB Garamond', serif;
    font-size: 27px;
}

h4, h3 { font-family: 'EB Garamond', serif; color: #ad6d49; text-transform: uppercase; }

p {
    font-family: 'EB Garamond', serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.37px;
}

.line-squares {
    display: flex;
    margin: 20px 0 30px 0;
    
    .square {
        width: 9px;
        height: 9px;
        background-color: #ad6d49;
        transform: rotate(45deg);
        margin-right: 7px;
    }
}

.white-text {color: white;}

.black-text { color: #1b1716; }

.bouton {
    transition: all .2s;
    text-transform: uppercase;
    font-family: 'EB Garamond', serif;
    font-weight: 600;
}

a[title="Panier"] {
    position: relative;
    
    #notif-panier {
        position: absolute;
        top: 12px;
        left: -7px;
        background-color: #ad6d49;
        width: 16px;
        height: 16px;
        border-radius: 15px;
        font-weight: 400;
        font-size: 12px;
        display: flex;
        justify-content: center;
        line-height: 16px;
        color: white !important;
    }
}
a[title="Panier"]:hover #notif-panier { color: white !important }

input:focus, button:focus, a:focus, select:focus, textarea:focus { outline: none; text-decoration: none }

#presentation {
    position: relative;
    img.pres { display: none; position: absolute; top: 0; left: 0 }
    img.pres:first-child { display: block; position: static }
}

#rgpd {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #f2eeec;
    display: flex;
    justify-content: center;
    padding: 10px;
    align-items: center;
    z-index: 500;

    .bouton {
        background-color: #ad6d49;
        border: 2px solid #ad6d49;
        border-radius: 23px;
        padding: 5px 20px;
        letter-spacing: 1.05px;
        font-size: 15px;
        color: white !important;
        margin-left: 50px;
    }
    
    .bouton:hover {
        color: white !important;
        cursor: url("/images/Cursor_1.png") 5 0, pointer;
    }

    p { line-height: 35px; margin-bottom: 0 }
}
 
.filters { display: none !important }
.home-prod-wrap { margin-top: 0 !important }

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
  
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

    #appointmentContainer {
        width: 100%;
        height: 15px;
    }

    #giftVoucherContainer {
        width: 100%;
        height: 15px;
    }

    .planity {
        margin-top:15px;
        width: 100%;
    }


@import "/components/nav-panier.scss";
@import "/components/presentation.scss";
@import "/components/tarifs.scss";
@import "/components/produits.scss";
@import "/components/gallery.scss";
@import "/layout/footer.scss";