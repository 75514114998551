header {
    height: 700px;
    background-size: cover;
    
    .horaires {
        display: flex;
        justify-content: center;
        margin: -50px 0;

        .line-squares { margin: 15px 0 40px 0 }
        
        article {
            display: flex;
            flex-direction: column;
            align-items: center;
            
            p {
                text-align: center;
                font-size: 20px;
                font-weight: 300;
                line-height: 25px;
            }
        }
    }
    .mob-panier { font-size: 24px;
        a { color: white !important }
        #notif-panier {     top: 23px !important; left: -14px !important }
    }
}

.mob-ui { display: none }
#mob-nav { display: none }

#main-nav {
    height: 382px;
    background: linear-gradient(#1b1716, 70%, transparent);
    
    ul {
        display: flex;
        justify-content: space-around;
        list-style: none;
        margin: auto;
        align-items: center;
        width: 70%;
        padding-top: 31px;
        padding-inline-start: 0;
        
        a {
            font-family: 'EB Garamond', serif;
            color: white;
            font-size: 15px;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 0.7px;
        }
        
        #logo a { margin: 0 50px; display: inline-block }
        .fa-shopping-bag, .fa-user-tie { font-size: 24px }
    }
    
}