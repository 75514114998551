#nav-panier {
    position: relative;
    
    #panier-hover {
        position: absolute;
        top: 170%;
        right: -20px;
        display: none;
        z-index: 100;
        
        #panier-hover-contain {
            background-color: white;
            width: 300px;
            border-radius: 5px;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            border: 1px solid #f2eeec;

            .row {
                background-color: #f2eeec;
                border-radius: 5px;
                width: 95%;
                margin: 10px 0;
            }
            
            #nav-panier-vide {
                text-align: center;                
                line-height: 50px;
                height: 50px;;
                
                p { font-size: 13px }
            }

            #nav-panier-plein {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;

                .row {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    position: relative;
                    padding-right: 30px;
                    margin-bottom: 0;

                    img { width: 80px; margin: 5px }
                    .objet-desc {
                        padding-left: 3px;
                        p { padding-top: 5px; margin-bottom: 0 }
                        .titre { font-weight: 600 }
                        .prix { font-weight: 600; color: #ad6d49 }
                    }
                    .fa-times { position: absolute; top: 10px; right: 10px; color: grey; cursor: url(/images/Cursor_1.png) 5 0, pointer }
                }

                .total {
                    margin: 20px 0 10px 15px;
                    align-self: start;
                    font-weight: 600;
                    .prix { color: #ad6d49 }
                }
                a { color: black; font-size: 12px; text-align: center; margin: 10px 0 }
            }
        }
        #panier-hover-contain::before {
            content: "\25b2";
            position: absolute;
            bottom: 100%;
            color: white;
            font-size: 20px;
            line-height: 13px;
            width: 85%;
            text-align: right;
        }
    }
}